import React, { useState } from 'react';
import { Upload, Camera, FileText, CheckCircle, ChevronDown, ChevronUp, Minus, Plus } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './ui/alert';
import { ImageProcessingService } from '../services/ImageProcessingService';
import { ReportService } from '../services/ReportService';
import ReportOptionsModal from './ReportOptionsModal';

const Program = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [processingStatus, setProcessingStatus] = useState('idle');
  const [analysisResults, setAnalysisResults] = useState(null);
  const [error, setError] = useState(null);
  const [showImages, setShowImages] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('border-blue-500');
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('border-blue-500');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-blue-500');
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  const handleUpdateQuantity = (itemId, delta) => {
    setAnalysisResults(prev => {
      if (!prev) return null;
      
      const updatedItems = prev.items.map(item => {
        if (item.id === itemId) {
          const newQuantity = Math.max(1, item.quantity + delta);
          return {
            ...item,
            quantity: newQuantity,
            totalPrice: item.price * newQuantity
          };
        }
        return item;
      });

      const totalValue = updatedItems.reduce((sum, item) => sum + item.totalPrice, 0);

      return {
        ...prev,
        items: updatedItems,
        totalValue
      };
    });
  };

  const handleFiles = async (files) => {
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    if (imageFiles.length === 0) {
      setError('Please upload image files only');
      return;
    }

    try {
      setError(null);
      setUploadedFiles(prev => [...prev, ...imageFiles]);
      setProcessingStatus('processing');

      console.log('Starting image processing...');
      const responses = await ImageProcessingService.processImages(imageFiles);
      console.log('API responses:', responses);

      const formattedItems = ImageProcessingService.formatResults(responses);
      console.log('Formatted items:', formattedItems);

      // Add IDs to items
      const itemsWithIds = formattedItems.map((item, index) => ({
        ...item,
        id: `item-${Date.now()}-${index}`,
        totalPrice: item.price * (item.quantity || 1)
      }));

      const totalValue = itemsWithIds.reduce((sum, item) => sum + item.totalPrice, 0);

      setAnalysisResults({
        items: itemsWithIds,
        totalValue
      });

    } catch (error) {
      console.error('Error processing images:', error);
      setError(error.message || 'Failed to process images. Please try again.');
    } finally {
      setProcessingStatus('idle');
    }
  };

  const handleGenerateReport = async (format) => {
    if (!analysisResults) return;
    
    try {
      setProcessingStatus('generating');
      
      if (format === 'pdf') {
        await ReportService.generatePDFReport(analysisResults, uploadedFiles);
      } else if (format === 'excel') {
        await ReportService.generateExcelReport(analysisResults, uploadedFiles);
      }
      
      setProcessingStatus('idle');
    } catch (error) {
      console.error(`Error generating ${format} report:`, error);
      setError(`Failed to generate ${format} report. Please try again.`);
      setProcessingStatus('idle');
    }
  };

  return (
    <div className="min-h-screen pt-16 bg-gray-50">
      <div className="h-full max-w-6xl mx-auto px-4 py-8">
        <div className="mb-8 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold mb-4">Contents Claim Generator</h1>
            <p className="text-gray-600">Upload photos of your items to generate a detailed claim list</p>
          </div>
          {analysisResults && (
            <button
              onClick={() => setIsReportModalOpen(true)}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
            >
              <FileText className="w-4 h-4 mr-2" />
              Generate Report
            </button>
          )}
        </div>

        {error && (
          <Alert variant="destructive">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Results Section */}
        {analysisResults && (
          <div className="mb-8">
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="p-4 border-b bg-gray-50">
                <h2 className="text-xl font-semibold">Identified Items</h2>
              </div>
              <div className="relative">
                <div className="max-h-[400px] overflow-y-auto">
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-50">Item</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-50">Model</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-50">Source</th>
                          <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase bg-gray-50">Quantity</th>
                          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase bg-gray-50">Unit Price</th>
                          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase bg-gray-50">Total Price</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {analysisResults.items.map((item) => (
                          <tr key={item.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.model}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.source}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                              <div className="flex items-center justify-center space-x-2">
                                <button
                                  onClick={() => handleUpdateQuantity(item.id, -1)}
                                  className="p-1 rounded-full hover:bg-gray-100"
                                >
                                  <Minus className="w-4 h-4" />
                                </button>
                                <span>{item.quantity}</span>
                                <button
                                  onClick={() => handleUpdateQuantity(item.id, 1)}
                                  className="p-1 rounded-full hover:bg-gray-100"
                                >
                                  <Plus className="w-4 h-4" />
                                </button>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                              ${item.price.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                              ${item.totalPrice.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-50 sticky bottom-0 z-10">
                        <tr className="font-semibold">
                          <td colSpan={5} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 bg-gray-50">Total Value</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right bg-gray-50">
                            ${analysisResults.totalValue.toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Upload Area */}
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 border-dashed border-gray-300 rounded-lg p-8 text-center transition-colors hover:border-blue-400 bg-white ${
            uploadedFiles.length === 0 ? 'min-h-[400px] flex items-center justify-center' : ''
          }`}
        >
          <div className="flex flex-col items-center space-y-4">
            <Upload size={48} className="text-blue-500" />
            <div>
              <p className="text-lg font-medium">Drag and drop your photos here</p>
              <p className="text-gray-500">or</p>
              <label className="inline-block mt-2">
                <input
                  id="file-input"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileInput}
                  className="hidden"
                />
                <span className="bg-blue-500 text-white px-6 py-2 rounded-full cursor-pointer hover:bg-blue-600 transition-colors">
                  Browse Files
                </span>
              </label>
            </div>
          </div>
        </div>

        {/* Processing Status */}
        {processingStatus === 'processing' && (
          <Alert className="mt-8">
            <div className="flex items-center">
              <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mr-2" />
              <AlertTitle>Processing Your Images</AlertTitle>
            </div>
            <AlertDescription>
              Analyzing items and retrieving product information...
            </AlertDescription>
          </Alert>
        )}

        {processingStatus === 'generating' && (
          <Alert className="fixed bottom-4 right-4 max-w-md">
            <div className="flex items-center">
              <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mr-2" />
              <AlertTitle>Generating Report</AlertTitle>
            </div>
            <AlertDescription>
              Please wait while we prepare your report...
            </AlertDescription>
          </Alert>
        )}

        {/* Uploaded Photos Preview */}
        {uploadedFiles.length > 0 && (
          <div className="mt-8 bg-white p-6 rounded-lg shadow-sm">
            <button
              onClick={() => setShowImages(!showImages)}
              className="w-full flex items-center justify-between text-lg font-semibold p-2 hover:bg-gray-50 rounded-lg transition-colors"
            >
              <div className="flex items-center space-x-2">
                <Camera className="w-5 h-5" />
                <span>Uploaded Photos ({uploadedFiles.length})</span>
              </div>
              {showImages ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
            </button>
            
            <div className={`transition-all duration-300 ease-in-out overflow-hidden ${
              showImages ? 'max-h-[2000px] opacity-100 mt-4' : 'max-h-0 opacity-0'
            }`}>
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {uploadedFiles.map((file, index) => (
                  <div key={index} className="aspect-square rounded-lg bg-gray-100 flex items-center justify-center">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Uploaded file ${index + 1}`}
                      className="max-w-full max-h-full object-cover rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Report Options Modal */}
        <ReportOptionsModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onGenerateReport={handleGenerateReport}
        />
      </div>
    </div>
  );
};

export default Program;