import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export class ReportService {
  static async generatePDFReport(analysisResults, uploadedFiles) {
    const doc = new jsPDF();
    
    // Add header
    doc.setFontSize(20);
    doc.text('Contents Claim Report', 20, 20);
    
    doc.setFontSize(12);
    doc.text(`Generated on ${new Date().toLocaleDateString()}`, 20, 30);
    doc.text(`Total Items: ${analysisResults.items.length}`, 20, 40);
    doc.text(`Total Value: $${analysisResults.totalValue.toFixed(2)}`, 20, 50);

    // Create table data matching the displayed table
    const tableData = analysisResults.items.map(item => [
      item.name,
      item.model,
      item.source,
      item.quantity.toString(),
      `$${item.price.toFixed(2)}`,
      `$${(item.price * item.quantity).toFixed(2)}`
    ]);

    // Add table
    doc.autoTable({
      startY: 60,
      head: [['Item', 'Model', 'Source', 'Quantity', 'Unit Price', 'Total Price']],
      body: tableData,
      foot: [['', '', '', '', 'Total:', `$${analysisResults.totalValue.toFixed(2)}`]],
      theme: 'grid',
      headStyles: {
        fillColor: [66, 139, 202],
        textColor: 255,
        fontSize: 10
      },
      footStyles: {
        fillColor: [240, 240, 240],
        textColor: 0,
        fontStyle: 'bold'
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      }
    });

    // Add uploaded photos section if there are any
    if (uploadedFiles.length > 0) {
      doc.addPage();
      doc.setFontSize(16);
      doc.text('Uploaded Photos', 20, 20);

      const margin = 20;
      const pageWidth = doc.internal.pageSize.width - 2 * margin;
      const photosPerRow = 2;
      const photoWidth = pageWidth / photosPerRow;
      const photoHeight = photoWidth * 0.75;
      const spacing = 10;

      let currentY = 40;
      let currentX = margin;

      for (let i = 0; i < uploadedFiles.length; i++) {
        if (currentY + photoHeight > doc.internal.pageSize.height - margin) {
          doc.addPage();
          currentY = margin;
        }

        try {
          const imgUrl = URL.createObjectURL(uploadedFiles[i]);
          const img = await this.loadImage(imgUrl);
          
          doc.addImage(
            img,
            'JPEG',
            currentX,
            currentY,
            photoWidth - spacing,
            photoHeight - spacing
          );

          URL.revokeObjectURL(imgUrl);

          if ((i + 1) % photosPerRow === 0) {
            currentX = margin;
            currentY += photoHeight;
          } else {
            currentX += photoWidth;
          }
        } catch (error) {
          console.error('Error adding photo to PDF:', error);
        }
      }
    }

    // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }

    // Save the PDF
    doc.save(`contents-claim-report-${new Date().toISOString().split('T')[0]}.pdf`);
  }

  static async generateExcelReport(analysisResults, uploadedFiles) {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Prepare data for the main sheet
    const mainSheetData = [
      // Header row
      ['Item', 'Model', 'Source', 'Quantity', 'Unit Price', 'Total Price'],
      // Data rows
      ...analysisResults.items.map(item => [
        item.name,
        item.model,
        item.source,
        item.quantity,
        item.price,
        item.price * item.quantity
      ]),
      // Empty row
      [],
      // Total row
      ['', '', '', '', 'Total:', analysisResults.totalValue]
    ];

    // Create the main worksheet
    const ws = XLSX.utils.aoa_to_sheet(mainSheetData);

    // Style the header
    const headerRange = XLSX.utils.decode_range(ws['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1";
      if (!ws[address]) continue;
      ws[address].s = { font: { bold: true } };
    }

    // Style the numbers (format as currency)
    const currencyFormat = '"$"#,##0.00';
    for (let R = 1; R <= mainSheetData.length; ++R) {
      const priceCell = XLSX.utils.encode_cell({ r: R, c: 4 });
      const totalCell = XLSX.utils.encode_cell({ r: R, c: 5 });
      if (ws[priceCell]) ws[priceCell].z = currencyFormat;
      if (ws[totalCell]) ws[totalCell].z = currencyFormat;
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Claim Items');

    // Create summary sheet
    const summaryData = [
      ['Contents Claim Summary'],
      [],
      ['Generated Date', new Date().toLocaleDateString()],
      ['Total Items', analysisResults.items.length],
      ['Total Value', analysisResults.totalValue],
      [],
      ['Uploaded Photos', uploadedFiles.length]
    ];

    const summaryWS = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, summaryWS, 'Summary');

    // Save the Excel file
    XLSX.writeFile(wb, `contents-claim-report-${new Date().toISOString().split('T')[0]}.xlsx`);
  }

  static loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }
}