import React from "react";
import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = ["Product Name", "Model", "Quantity", "Unit Price", "Total Price", "Source"];

const TABLE_ROWS = [
  {
    name: "Ultra HD TV6",
    model: "QN65Q80B",
    quantity: 1,
    unitPrice: 999.99,
    totalPrice: 999.99,
    source: "Best Buy",
  },
  {
    name: "Wireless Headphones",
    model: "WH-1000XM4",
    quantity: 2,
    unitPrice: 199.99,
    totalPrice: 399.98,
    source: "Amazon",
  },
  {
    name: "Coffee Maker",
    model: "KCM1202OB",
    quantity: 1,
    unitPrice: 79.99,
    totalPrice: 79.99,
    source: "Target",
  },
  {
    name: "Running Shoes",
    model: "Air Zoom Pegasus 37",
    quantity: 1,
    unitPrice: 129.99,
    totalPrice: 129.99,
    source: "Nike Store",
  },
];

export function TableWithFooter() {
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="p-4 pt-10">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-bold leading-none"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TABLE_ROWS.map(({ name, model, quantity, unitPrice, totalPrice, source }) => (
            <tr key={name}>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-bold"
                >
                  {name}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  {model}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  {quantity}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  ${unitPrice.toFixed(2)}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  ${totalPrice.toFixed(2)}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  {source}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-t border-gray-300">
          <tr>
            <td className="p-4">
              <Typography
                color="blue-gray"
                variant="small"
                className="font-bold"
              >
                Total
              </Typography>
            </td>
            <td className="p-4"></td>
            <td className="p-4">
              <Typography
                color="blue-gray"
                variant="small"
                className="font-bold"
              >
                5
              </Typography>
            </td>
            <td className="p-4"></td>
            <td className="p-4">
              <Typography
                color="blue-gray"
                variant="small"
                className="font-bold"
              >
                $1609.95
              </Typography>
            </td>
            <td className="p-4"></td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
}
