import OpenAI from 'openai';

export class ImageProcessingService {
  static async processImages(files) {
    const responses = [];
    
    try {
      if (!process.env.REACT_APP_OPENAI_API_KEY) {
        throw new Error('OpenAI API key is missing. Please check your environment variables.');
      }

      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY.trim(),
        dangerouslyAllowBrowser: true
      });

      for (const file of files) {
        try {
          console.log('Processing file:', file.name);
          const base64Image = await this.fileToBase64(file);
          
          const response = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: [
              {
                "role": "system",
                "content": [
                  {
                    "text": "You are a helpful assistant that analyzes images and provides information about items in JSON format. Always respond with a JSON object containing an 'items' array with objects having 'name', 'model', 'quantity', 'price', and 'source' fields. If you cannot find the exact item, find a similar replacement. Focus on getting accurate pricing for the items, accurate  quantity, and accurate names for the items.\n\nThe source field should be a website where it and the price that was given can be found , ie : Amazon, Home  Depot,  Best Buy, etc.\n\nThe price response should be in the format \"$0.00\"",
                    "type": "text"
                  }
                ]
              },
              {
                "role": "user",
                "content": [
                  {
                    "type": "image_url",
                    "image_url": {
                      "url": `data:image/jpeg;base64,${base64Image}`
                    }
                  }
                ]
              }
            ],
            response_format: {
              "type": "json_object"
            },
            temperature: 0.3,
            max_tokens: 16383,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0
          });

          console.log('Raw OpenAI response:', response.choices[0].message.content);

          if (!response?.choices?.[0]?.message?.content) {
            throw new Error('Invalid response format from API');
          }

          responses.push(response.choices[0].message.content);
          
        } catch (error) {
          console.error('Image processing error:', error);
          throw new Error(`Failed to process image: ${error.message}`);
        }
      }

      return responses;
      
    } catch (error) {
      console.error('OpenAI service error:', error);
      throw error;
    }
  }

  static formatResults(responses) {
    let allItems = [];
    
    for (const response of responses) {
      try {
        console.log('Processing response:', response);
        const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
        console.log('Parsed response:', parsedResponse);

        if (parsedResponse?.items && Array.isArray(parsedResponse.items)) {
          const formattedItems = parsedResponse.items.map(item => {
            // Parse quantity if it exists, otherwise default to 1
            const quantity = item.quantity ? parseInt(item.quantity) : 1;
            const price = this.parsePrice(item.price);

            console.log('Formatting item:', item, 'Quantity:', quantity, 'Price:', price);

            return {
              name: item.name || "Unknown Item",
              model: item.model || "Unknown",
              source: item.source || "Unknown",
              quantity: quantity,
              price: price,
              totalPrice: price * quantity
            };
          });

          console.log('Formatted items:', formattedItems);
          allItems = [...allItems, ...formattedItems];
        }
      } catch (error) {
        console.error('Error parsing response:', error);
        console.log('Problematic response:', response);
        throw new Error('Failed to parse API response');
      }
    }
    
    console.log('Final formatted items:', allItems);
    return allItems;
  }

  static parsePrice(priceString) {
    if (!priceString) return 0;
    try {
      const numericPrice = parseFloat(priceString.replace(/[$,]/g, ''));
      return isNaN(numericPrice) ? 0 : numericPrice;
    } catch (error) {
      console.error('Error parsing price:', priceString, error);
      return 0;
    }
  }

  static fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}